.modal {
  $base-border-color: #dcdcdc !default;
  $base-border-radius: 3px !default;
  $base-background-color: #fff !default;
  $base-font-size: 1em !default;
  $base-line-height: 1.5em !default;
  $action-color: #477dca !default;
  $dark-gray: #333 !default;
  $light-gray: #ddd !default;
  $medium-screen: 40em !default;
  $large-screen: 53.75em !default;
  $base-font-color: $dark-gray !default;
  $modal-padding: 3em;
  $modal-background: $base-background-color;
  $modal-close-color: $light-gray;
  $modal-image-height: 135px;
  $modal-image-width: $modal-image-height;
  $modal-trigger-image-width: 300px;

  label {
    cursor: pointer;
    margin-bottom: 0;
  }

  label img {
    border-radius: $modal-trigger-image-width / 2;
    display: block;
    max-width: $modal-trigger-image-width;
  }

  .modal-state {
    display: none;
  }

  .modal-trigger {
    padding: 0.8em 1em;
  }

  .modal-fade-screen { // overlay
    @include position(fixed, 0);
    background-color: rgba(#000, 0.85);
    opacity: 0;
    padding-top: 0.6em;
    text-align: left;
    transition: opacity 0.25s ease;
    visibility: hidden;
    z-index: 99999999999;

    @include media($large-screen) {
      padding-top: 10em;
    }

    .modal-bg {
      @include position(absolute, 0);
      cursor: pointer;
    }
  }

  .modal-close {
    @include position(absolute, ($modal-padding /2) ($modal-padding /2) null null);
    @include size(1.5em);
    background: $modal-background;
    cursor: pointer;

    &::after,
    &::before {
      @include position(absolute, 3px 3px 0 50%);
      @include size(0.15em 1.5em);
      background: $modal-close-color;
      content: "";
      display: block;
      margin: -3px 0 0 -1px;
      transform: rotate(45deg);
    }

    &:hover::after,
    &:hover::before {
      background: darken($modal-close-color, 10%);
    }

    &::before {
      transform: rotate(-45deg);
    }
  }

  .modal-inner {
    background: $modal-background;
    border-radius: $base-border-radius;
    margin: auto;
    margin-top: 0;
    max-height: 95%;
    overflow: auto;
    padding: $modal-padding / 2;
    position: relative;
    transition: opacity 0.25s ease;
    width: 95%;

    @include media($medium) {
      max-height: 70%;
      padding: $modal-padding;
      width: 60%;
    }

    @include media($large) {
      width: 50%;
    }

    h1 {
      color: $base-font-color;
      text-align: left;
      text-transform: capitalize;
       font-size: rem(24);
    }

    p {
      color: $base-font-color;
      line-height: $base-line-height;
      font-size: rem(14);
      font-family: $alt-font-family;
    }


    a.cta {
      color: $base-background-color;
      display: inline-block;
      margin-right: 0.5em;
      margin-top: 1em;

      &:last-child {
        padding: 0 2em;
      }
    }
  }

  .modal-state:checked + .modal-fade-screen {
    opacity: 1;
    visibility: visible;
  }

  .modal-state:checked + .modal-fade-screen .modal-inner {
    top: 0.5em;
  }
}

.modal-open {
  overflow: hidden;
}
