// /* Mixins =================================================== */


// @include box-shadow(TOP, LEFT, BLUR, COLOR, SPREAD, INSET))
@mixin box-shadow($top, $left, $blur, $spread, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $spread $color;
    -moz-box-shadow:inset $top $left $blur $spread $color;
    box-shadow:inset $top $left $blur $spread $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $spread $color;
    -moz-box-shadow: $top $left $blur $spread $color;
    box-shadow: $top $left $blur $spread $color;
  }
}



// // ELEMENTS ==============================

// .background-size(VALUE VALUE);
@mixin background-size($size){
	-webkit-background-size : $size;
	-moz-background-size : $size;
	-o-background-size : $size;
	background-size : $size;
}


// ------------------------------------------------------------
// Omega Reset
// ------------------------------------------------------------

@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) { margin-right: flex-gutter(); }
  &:nth-child(#{$nth}+1) { clear: none }
}
