
/**
 * TYPOGRAPHY
 * ==============================
 */


// Custom Font Stacks
$sans-serif:		"Open Sans", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Verdana, Tahoma, sans-serif;
$serif: 			"Roboto Slab", "Hoefler Text", Cambria, "Bitstream Charter", Georgia, "Dejavu Serif", serif;

// General Font Fmaily Definitions
$base-font-family: 	$sans-serif;
$alt-font-family: 	$serif;

$heading-font-family: $base-font-family;

// Font Sizes
$base-font-size: 1em;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 2px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

/**
 * COLORS
 * ==============================
 */
// General Colors
$blue: 			#1565c0;
$dark-gray: 	#555;
$medium-gray: 	#999;
$light-gray: 	#ddd;

// Brand Colors
$ads-darkbrown:		#2D2D2D;
$ads-brown:			#908766;
$ads-lightbrown:	#F0F2E1;
$ads-orange:	 	#F36E51;


// Font Colors
$base-font-color: $dark-gray;
//$action-color: $blue;
$action-color: $ads-orange;

// Links
$link-color:		$action-color;
$link-colorvisited:	$link-color;
$link-colorhover:	lighten( $link-color, 20% );
$link-colorfocus:	$action-color;

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Background Colors
$base-background-color: #fff;
$secondary-background-color: tint($base-border-color, 75%);

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

// Animations
$base-duration: 150ms;
$base-timing: ease;


/**
 * BREAKPOINTS
 * ==============================
 */
$small: 	new-breakpoint(min-width 480px);
$medium: 	new-breakpoint(min-width 767px);
$large: 	new-breakpoint(min-width 992px);
$xlarge: 	new-breakpoint(min-width 1300px);

