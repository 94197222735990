// /* Page =================================================== */

// // 1. ROOT 					==============================
// *, *:before, *:after { @include box-sizing(border-box); }


@-webkit-viewport {
width : device-width; }

@-moz-viewport {
width : device-width; }

@-ms-viewport {
width : device-width; }

@-o-viewport {
width : device-width; }

@viewport {
width : device-width; }


html {
	//background: $base-background-color ;
	background-color: #2d2d2d;
	background-attachment: fixed;
	-webkit-overflow-scrolling : touch;
	-webkit-tap-highlight-color : lighten( $base-font-color, 75% );
	-webkit-text-size-adjust : 100%;
	-ms-text-size-adjust : 100%;
}

body {
	background-color: transparent;
	font-family : $base-font-family;
	line-height : $base-line-height;
	//color : $text-color;

}

a {
	text-decoration : none;
	color : $link-color;

	&:visited {
		color : $link-colorvisited; }

	&:hover {
		text-decoration : underline;
		color : $link-colorhover; }

	&:focus {
		outline : thin dotted;
		color : $link-colorfocus; }

	&:hover,
	&:active {
		outline : 0; }
}

::-moz-selection {
	background-color : lighten( $action-color, 10% );
	color : $base-font-color;
	text-shadow : none; }

::selection {
	background-color : lighten( $action-color, 10% );
	color : $base-font-color;
	text-shadow : none; }



/**
 * TYPOGRAPHY
 * ==============================
 */

h1 {
	line-height: $heading-line-height;
	color: #fff;
	margin: 0 0 $base-spacing;
	font-family: $alt-font-family;
	font-weight: 300;
	font-size: rem(26);

	@include media($small) {
		font-size: rem(42);
	}

	@include media($medium) {
		font-size: rem(62);
	}

	@include media($large) {
		font-size:  rem(80);
	}

}

h2 {
	color: $ads-darkbrown;
	line-height: $heading-line-height;
	font-family: $alt-font-family;
	font-weight: 700;
	margin-bottom: 0.5em;
	font-size: rem(24);

	@include media($small) {
		font-size: rem(26);
	}
	@include media($medium) {
		font-size: rem(30);

	}
	@include media($xlarge) {
		font-size: rem(36);
	}
}

h3 {
	color: $ads-brown;
	text-transform: uppercase;
	font-weight: 700;
	line-height: $heading-line-height;
	margin-bottom: $small-spacing;
	font-size: rem(16);

	@include media($small) {
		font-size: rem(18);
	}
	@include media($medium) {
		font-size: rem(20);

	}
	@include media($xlarge) {
		font-size: rem(24);
	}
}

h4 {
	line-height: $heading-line-height;
	// color: desaturate( darken( $cb-tan, 10% ), 20% );
	font-family: $alt-font-family;
	font-weight: 300;
	margin-bottom: $heading-line-height;
	font-size: rem(16);

	@include media($small) {
		font-size: rem(19);
	}

	@include media($medium) {
		font-weight: 400;
		font-size: rem(22);
	}

	@include media($large) {
		font-size:  rem(26);
	}

}

p, li {
	font-weight: 400;
	line-height: $base-line-height;
	margin-bottom: $base-line-height;
	color: #333;
	font-size: rem(15);


	@include media($small) {
		color: $base-font-color;
		font-size: rem(16);
	}
	@include media($medium) {
		//font-weight: 300;
		font-size: rem(19);
	}
	@include media($xlarge) {
		//font-size: rem(21);
	}


	strong {
		font-weight: 700;
	}

}

/**
 * GLOBAL STRUCTURE
 * ==============================
 */

// Wrapper ====================
.wrapper {
	padding-left: 5%;
	padding-right: 5%;
	width: auto;
	position: relative;
	@include outer-container;

	@include media($medium) {
		padding-left: 2%;
		padding-right: 2%;
	}

	@include media($large) {
		padding-left: 0;
		padding-right: 0;
		width: 960px;
		margin-left: auto;
		margin-right: auto;
	}

	@include media($xlarge) {
		width: 1170px;
	}
}

.banner-container {
	background-color: #212121;

	&.lp {
		background: #212121 url('../img/banner-01.jpg') no-repeat 50% 50%;
		@include background-size( cover );
	}
}

.masthead {
	@include outer-container;
	padding: 0.5em 0 0.25em;

	@include media($medium) {
		padding-top: 1em;
		padding-bottom: .5em;
	}

	.logo {
		@include span-columns(6);
		@include shift(3);
		font-weight: 400;
		text-align: center;
		font-size: rem(20);
		color: #fff;
		text-align: left;

		@include media($small) {
			@include span-columns(4);
			@include shift(0);

		}

		@include media($medium) {
			@include span-columns(3);
			font-size: rem(25);
		}
	}
}

.banner-content {
	padding-top: $base-spacing * 2;
	padding-bottom: $base-spacing * 1;
	text-align: center;

	h1 {
		margin-bottom: $small-spacing;

		@include media($medium) {
			font-size: rem(45);
		}

		@include media($large) {
			font-size: rem(50);
		}
	}

	p {
		letter-spacing: 0.06em;
		font-size: rem(20);
		color: $ads-orange;
		font-weight: 700;
		text-transform: uppercase;
		font-style: italic;
	}

	@include media($medium) {
		padding-top: $base-spacing * 2;
		text-align: left;
		@include span-columns(6);
	}

	@include media($large) {
		padding-top: $base-spacing * 2;
		padding-bottom: $base-spacing * 3;

		p {
			font-size: rem(24);
		}

	}
}


.main-content {
	background: #fff url("../img/bg-logomark-lg.png") no-repeat bottom right;
	padding-bottom: $base-spacing * 2;
	padding-top: 1px;

	&.space {
		padding-top: $base-spacing * 2;
	}
}

.content-wrapper {
	@include clearfix;
	padding: $base-spacing;
	background: rgba(255,255,255,0.6);
	border-radius: 4px;
	box-shadow: 0 2px 2px rgba(0,0,0,0.1), 0 2px 10px rgba(0,0,0,0.1);

	@include media($medium) {
		padding: $base-spacing * 2;
	}
}

@include direction-context(right-to-left) {

	#form-rail {
		@include media($medium) {
			@include span-columns(5);
		}
	}
}

/* Form */
#form-rail {
	margin-top: $base-spacing;

	@include media($medium) {
		margin-top: ($base-spacing * -12);
	}
}

.form-wrapper {
	background: $ads-lightbrown;
	padding-top: $base-spacing;
	border-radius: 10px;
	box-shadow: 0 2px 3px 1px rgba(0,0,0,0.15), 0 3px 12px rgba(0,0,0,0.075);
	z-index: 100;

	header {
		padding: ($small-spacing / 2) $base-spacing;
		background: $ads-brown;

		span {
			float: left;
			width: $base-spacing * 2.25;
			margin: ($base-spacing * -0.5) ($base-spacing * -0.35) ($base-spacing * -0.75) ($base-spacing * -1.8);
			z-index: 2;
			position: relative;
		}
	}

	h2 {
		color: lighten( $ads-lightbrown, 8% );
		font-family: $base-font-family;
		margin-bottom: 0;
		font-size: rem(20);
		z-index: 0;
	}

	form {
		padding:  $base-spacing $base-spacing $small-spacing;
	}

	label {
		font-size: rem(14);
		color: $ads-brown;
	}

	input {
		font-size: rem(22);
		color: $ads-darkbrown;
	}

	.button-holder {
		text-align: center;
		padding: $base-spacing $small-spacing;
	}
	.btn {
		text-transform: uppercase;
		padding: $base-spacing;
		letter-spacing: 0.04em;
		font-size: rem(16);
		width: 100%;
		border-radius: 4px;
	}

	@include media($small) {
		h2 { font-size: rem(24); }
		.btn { font-size: rem(18); }
	}
	@include media($medium) {
		header span {
			margin: ($base-spacing * -0.5) ($base-spacing * -0.35) ($base-spacing * -0.75) ($base-spacing * -0.85);
		}
		h2 { font-size: rem(20); }
		.btn { font-size: rem(15); }
	}
	@include media($large) {
		header span {
			margin: ($base-spacing * -0.5) ($base-spacing * -0.35) ($base-spacing * -0.75) ($base-spacing * -0.75);
		}
		h2 { font-size: rem(24); }
		.btn { font-size: rem(19); }
	}


}

/* Main Content */
#lp-content {
	padding-top: $base-spacing;

	p {
		font-size: rem(18);
		font-weight: 300;
		margin-bottom: $base-spacing;
	}

	li {
		font-size: rem(18);
		font-weight: 300;
		margin-bottom: $small-spacing;
	}

	ul {
		list-style-type: disc;
		padding-left: $base-spacing * 2;
	}

	h3 {
		padding-top: $base-spacing;
	}

	@include media($medium) {
		@include span-columns(6);
		padding-top: $base-spacing * 2;


		p {
			font-size: rem(24);
		}

		li {
			font-size: rem(20);
		}

	}
}

#degree-types {
	@include clearfix;
	background: $ads-brown;

	li {
		@include span-columns(6);
		@include omega(2n);
		padding: $base-spacing;
		font-family: $alt-font-family;
		font-size: rem(17);
		font-weight: 700;
		color: $ads-lightbrown;

		// &:hover {
		// 	background: lighten( $ads-brown, 2% );
		// }
	}

	@include media($small) {
		li {
			font-size: rem(20);
		}
	}

	@include media($medium) {
		li {
			@include omega-reset(2n);
			@include span-columns(3);

			font-size: rem(25);


		}
	}

	@include media($large) {
		li {
			font-size: rem(30);
		}
	}
}

/* FOOTER */
.site-footer {
	padding-bottom: $base-spacing;
	text-align: center;
	background: $ads-darkbrown;

	p {
		color: $ads-brown;
		font-size: .8em;
	}
}

#footer-legal {
	padding-top: $base-spacing * 2;

	a {
		color: $ads-brown;

		&:hover {
			color: $ads-lightbrown;
		}
	}
	nav {

	}

	@include media($medium) {
		.copyright {
			@include span-columns(8);
			text-align: left;
		}
		nav {
			@include span-columns(4);
			text-align: right;

		}
	}


}


/* Loading */


// LOADING ====================
.content-processing {
	text-align: center;

	@include media($medium) {
		@include span-columns(6);
		@include shift(3);
	}

	@include media($xlarge) {
		@include span-columns(8);
		@include shift(2);
	}
}

.loading {
	margin-top: $base-spacing;
	margin-bottom: $base-spacing;

	@include media($medium) {
		margin-top: $base-spacing * 2;
		margin-bottom: $base-spacing * 2;
	}

	@include media($xlarge) {
		margin-top: $base-spacing * 3;
		margin-bottom: $base-spacing * 3;
	}
}

// RESOURCES ====================

// #resource-frame {
// 	width: 100%;
// 	padding: 0;
// 	margin: 0;
// 	border: none;
// 	display: block;
// 	height:0px;
// 	overflow: hidden;
// }



